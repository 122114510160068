import { Section } from 'components/_layout/Section/Section';
import * as Common from 'components/_universal/Common';
import React from 'react';

import styled, { css } from 'styled-components';
import { B1, H2 } from 'styles/Typography.styled';

export const VerticalBorder = styled(Common.Div)(
    ({ theme: { colors } }) =>
        css`
            width: 1px;
            height: 251px;
            border-right: 1px solid ${colors.border};

            @media (max-width: 400px) {
                height: 400px;
            }
        `,
);

export const QuoteSection = () => {
    return (
        <Section variant="secondary">
            <Common.Div flex="row" gap="32px" alignItems="center">
                <VerticalBorder />
                <Common.Div flex="column" gap="20px">
                    <H2>
                        Codahead connects worlds of
                        <br />
                        hardware, firmware and software
                    </H2>
                    <B1>
                        to assist global businesses in their transformation
                        towards the future.
                    </B1>
                </Common.Div>
            </Common.Div>
        </Section>
    );
};
