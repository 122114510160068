import React from 'react';
import { ExploreOurOfferSection } from 'sections/Home/ExploreOurOfferSection';
import { Hero } from 'sections/Home/Hero/Hero';
import { HowWeWorkSection } from 'sections/Home/HowWeWorkSection';
import { OurImpactSection } from 'sections/Home/OurImpactSection';
import { TakeThisStepSection } from 'sections/_universal/TakeThisStepSection';
import { QuoteSection } from 'sections/Home/QuoteSection';
import { TestimonialsSection } from 'sections/Home/TestimonialsSection';
import { TrustedBySection } from 'sections/_universal/TrustedBySection';

export default () => {
    return (
        <>
            <Hero />
            <QuoteSection />
            <TrustedBySection />
            <ExploreOurOfferSection />
            <OurImpactSection />
            <HowWeWorkSection />
            <TestimonialsSection />
            <TakeThisStepSection />
        </>
    );
};
