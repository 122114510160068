import LogoBusinessInsider from 'assets/images/_logos/companies/logoBusinessInsider.svg';
import LogoEntrepreneur from 'assets/images/_logos/companies/logoEntrepreneur.svg';
import LogoNYTimes from 'assets/images/_logos/companies/logoNytimes.svg';
import LogoUnitedNations from 'assets/images/_logos/companies/logoUnitedNations.png';
import { Section } from 'components/_layout/Section/Section';
import * as Common from 'components/_universal/Common';
import { SquareGrid } from 'components/_universal/SquareGrid';
import React from 'react';
import styled, { css } from 'styled-components';
import { B1, B2, B3, H2, H3 } from 'styles/Typography.styled';

export const ImpactSquare = styled(Common.Div)(
    ({ theme: { colors } }) =>
        css`
            width: 255px;
            height: 255px;
            display: flex;
            justify-content: center;
            align-items: baseline;
            background: ${colors.background};
            flex-direction: column;
            padding: 40px;
        `,
);

export const CompanySquare = styled(Common.Div)(
    () =>
        css`
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        `,
);

export const UnitedNationText = styled(B3)(
    () =>
        css`
            text-align: end;
            font-size: 15px;
            line-height: normal;
            margin-right: 8px;
        `,
);

const TEXT_WHAT_WE_DO = `We seek global solutions to aid our clients in their deep tech transformation to meet the requirements of the modern web, mobile, cloud and prepare them for the upcoming future of web 3.0.`;
const TEXT_WHAT_WE_FOCUS_ON = `We focus on complex changes in our clients' tech infrastructure to make an impact not only on their business but also on the shape of the entire industry in which they operate.  We work with both international corporations and startups with a global reach.`;

const impactItems = [
    {
        content: (
            <>
                <H2 mb={1.5}>30 000+</H2>
                <B3>
                    Our solutions work on
                    <br />
                    many end devices globally
                </B3>
            </>
        ),

        key: 'devices',
    },
    {
        content: <Common.Svg src={LogoNYTimes} w="170px" />,

        key: 'newYorkTimes',
        variant: 'company',
    },
    {
        content: (
            <>
                <H2 mb={1.5}>13+</H2>
                <B3>
                    solutions based on AI we <br />
                    created.
                </B3>
            </>
        ),
        key: 'ai',
    },
    {
        content: (
            <Common.Div flex="column">
                <Common.Svg src={LogoUnitedNations} w="150px" />
                <UnitedNationText>
                    panel discussion
                    <br /> and showcase
                </UnitedNationText>
            </Common.Div>
        ),

        key: 'unitedNations',
        variant: 'company',
    },
    {
        content: <Common.Svg src={LogoBusinessInsider} w="120px" />,

        key: 'businessInsider',
        variant: 'company',
    },
    {
        content: (
            <>
                <H2 mb={1.5}>11+</H2>
                <B3>
                    The software and hardware we have development are used in
                    countries all over the world.
                </B3>
            </>
        ),

        key: 'countries',
    },
    {
        content: <Common.Svg src={LogoEntrepreneur} w="140px" />,

        key: 'entrepreneur',
        variant: 'company',
    },
    {
        content: (
            <>
                <Common.Div flex="row" alignItems="flex-end" mb={1.5}>
                    <H2 mr={2}>$ 1,5 </H2> <H3> bln</H3>
                </Common.Div>
                <B3>
                    is being processed by software <br />
                    products that we delivered to <br />
                    our clients every year.
                </B3>
            </>
        ),

        key: 'clients',
    },
];

export const OurImpactSection = () => {
    return (
        <Section title="our impact">
            <B1 mt={10}>{TEXT_WHAT_WE_DO}</B1>
            <B2 mt={6} mb={12}>
                {TEXT_WHAT_WE_FOCUS_ON}
            </B2>
            <SquareGrid items={impactItems} />
        </Section>
    );
};
